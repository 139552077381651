<template>
  <v-col cols="12">
    <base-card>
      <v-card-title> Control de Folios </v-card-title>
      <v-card-text>
        <v-row v-if="isAdmin">
          <v-col cols="12" sm="4">
            <v-select ref="cliente" v-model="idCompania" :items="detailClients" item-text="text" item-value="IdCompania"
              label="Compañia" prepend-inner-icon="mdi-sitemap" :error="companySelectorError"
              :rules="[validateCompanyField]" :error-messages="helperSelectorText" filter search-input>
              <template v-slot:prepend-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-text-field class="search-field" v-model="search" placeholder="Buscar"
                        prepend-inner-icon="mdi-magnify" hide-details />
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="2">
            <v-btn class="ma-2" style="background-color:rgb(204 33 40);color:white !important" @click="searchCompany">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
        <v-form ref="foliosForm" v-if="isHKA && idCompania > 0 && isSearchCompany">
          <v-row v-if="isAdmin">
            <v-col cols="12" sm="4">
              <v-switch class="mb-6" v-model="paramControlFolios" :label="`${paramControlFolios ? '(Activo)' : '(Inactivo)'
                } Control de folios`" hide-details inset />
            </v-col>
            <v-col cols="12" sm="4" class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="loadingFoliosHKA" @click="actualizarFoliosDesdeHKA" v-bind="attrs" v-on="on">
                    <v-icon>mdi-refresh</v-icon>
                    Actualizar folios
                  </v-btn>
                </template>
                <span>Actualizar folios desde Proveedor Tecnológico (PT)</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field ref="FoliosComprados" v-model="dataFolios.FoliosComprados"
                label="Cantidad de folios comprados" prepend-inner-icon="mdi-note-multiple" disabled />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field ref="FoliosDisponibles" v-model="dataFolios.FoliosDisponibles"
                label="Cantidad de folios disponibles" prepend-inner-icon="mdi-note-multiple" disabled />
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field ref="FoliosAlertar" v-model="dataFolios.FoliosAlertar"
                label="Alertar cuando falten Folios (%)" prepend-inner-icon="mdi-note-multiple" type="number" clearable
                :rules="formRulesFoliosAlertar" :error-messages="errorMessages" />
            </v-col>

            <v-col cols="12" sm="3">
              <v-text-field ref="FoliosRepetirAlerta" v-model="dataFolios.FoliosRepetirAlerta"
                label="Repetir alerta cada (Folios)" prepend-inner-icon="mdi-reload" type="number" clearable
                :rules="formRules" :error-messages="errorMessages" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="10" />
            <v-col cols="12" sm="2">
              <v-btn class="ma-2" style="
                  background-color: rgb(204 33 40);
                  color: white !important;
                " @click="validateFoliosData">
                Guardar
              </v-btn>
            </v-col>
            <v-alert v-show="successAlert" type="success" text>
              {{ msgSuccessAlert }}
            </v-alert>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-form ref="foliosFormToken" v-if="isHKA && isAdmin && idCompania > 0 && isSearchCompany">
          <v-row>
            <v-card-title> Token temporal para folios </v-card-title>
            <v-col cols="12">
              Genera un token para habilitar temporalmente la facturación de contingencia mientras el cliente adquiere
              nuevos
              folios.
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              <v-text-field ref="casoSalesforce" v-model="dataTokenFolios.casoSalesforce" label="Nro Caso Salesforce"
                :rules="formCasoSalesforceRules" prepend-inner-icon="mdi-note-multiple" type="number" />
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field ref="horasGracia" v-model="dataTokenFolios.horasGracia" label="Horas de Gracia Folios"
                :rules="formRulesHorasGraciaFolios" counter="2" prepend-inner-icon="mdi-note-multiple" type="number" />
            </v-col>
            <v-col cols="12" sm="2">
              <v-btn class="ma-3" style="background-color: rgb(204 33 40); color: white !important;" block
                @click="generateTokenFolios">
                Generar Token
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="isHKA === false">
          <div class="text-center ml-10 mt-5">
                <span class="text-subtitle-1">
                  Opción no disponible para el Proveedor Tecnológico EDN
                </span>
              </div>
        </v-row>
        <v-row>
          <v-alert v-show="errorAlert" type="warning" text>
            {{ msgErrorAlert }}
          </v-alert>
        </v-row>
        <v-row>
          <v-dialog max-width="500" v-model="dialogShowTokenFolios" transition="dialog-bottom-transition">
            <v-card>
              <v-toolbar dark style="background-color:rgb(204 33 40);color:white !important">

                <v-toolbar-title>Token Generado</v-toolbar-title>
                <v-btn icon class="ml-auto" @click="closeDialogShowTokenFolios">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="text-h3 text-center mt-4">
                {{ dataTokenFolios.tokenGenerado }}
              </v-card-text>
              <div class="text-center mt-2">
                <span class="text-subtitle-1">
                  Válido por 5 minutos
                </span>
              </div>
              <v-card-actions class="justify-end">
                <v-btn text @click="closeDialogShowTokenFolios">
                  Cerrar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-card-text>
    </base-card>
  </v-col>
</template>

<script>
import {listTechnologyProvider} from "@/data/listEnvironment";

export default {
  name: "ConfigAlertsFolios",

  data() {
    return {
      idCompania: 0,
      isHKA: null,
      loadingFoliosHKA: false,
      search: '',
      isAdmin: true,
      paramControlFolios: false,
      dataFolios: {
        FoliosComprados: "",
        FoliosDisponibles: "",
        FoliosAlertar: "",
        FoliosRepetirAlerta: "",
      },
      dataTokenFolios: {
        casoSalesforce: 0,
        horasGracia: 0,
        tokenGenerado: "",
        idCompania: 0,
        user: "",
      },
      dialogShowTokenFolios: false,
      helperSelectorText: '',
      companySelectorError: false,
      formHasErrors: null,
      successAlert: false,
      msgSuccessAlert: "Alerta para folios guardada exitosamente",
      errorAlert: false,
      errorMessages: '',
      msgErrorAlert: '',
      firstTime: true,
      isSearchCompany: false,
      formRules: [
        (v) => !!v || "Este campo es requerido (Solo acepta números)",
        (v) => v > 0 || "El valor del campo debe ser mayor a cero",
      ],
      formCasoSalesforceRules: [
        (v) => !!v || "Este campo es requerido (Solo acepta números)",
        (v) => v > 100000 || "El valor del campo debe 6 digitos",
      ],
      formRulesHorasGraciaFolios: [
        (v) => (v >= 1 && v <= 72) || "Solo permite de 1 a 72 horas de gracia"
      ],
      formRulesFoliosAlertar: [
        (v) => (v >= 1 && v <= 100) || "Solo permite valores de 1 a 100 en porcentaje"
      ],
    };
  },

  computed: {
    foliosForm() {
      return {
        FoliosComprados: this.dataFolios.FoliosComprados,
        FoliosDisponibles: this.dataFolios.FoliosDisponibles,
        FoliosAlertar: this.dataFolios.FoliosAlertar,
        FoliosRepetirAlerta: this.dataFolios.FoliosRepetirAlerta,
      };
    },
    statusResponseFolios() {
      return this.$store.state.folios.statusResponse;
    },
    detailClients() {
      if (this.isAdmin) {
        return (
          this.$store.state.folios.listClients.Mensaje || []
        )
          .filter((compania) => compania.Activo === 1)
          .filter((compania) =>
            compania.NombreCompania.toLowerCase().includes(
              this.search.toLowerCase()
            )
          )
          .map((compania) => ({
            ...compania,
            text: `[${compania.IdCompania}] ${compania.NombreCompania}`,
          }));
      }

      return (this.$store.state.folios.listClients.Mensaje || []).filter(
        (compania) =>
          compania.IdCompania ===
          JSON.parse(atob(localStorage.getItem("uid"))).idcompania
      );
    },
  },

  created() {
    let dataSesion = JSON.parse(atob(localStorage.getItem('uid')))
    this.isAdmin = dataSesion.rol === 'Administrador'
    this.dataTokenFolios.user = dataSesion.user
    if (!this.isAdmin) {
      this.idCompania = JSON.parse(atob(localStorage.getItem("uid"))).idcompania
      this.searchCompany()
    }

    this.getListClients();

  },

  watch: {
    "$store.state.folios.foliosData": {
      handler(data) {
        if (data.FoliosComprados === undefined) {
          this.dataFolios.FoliosComprados = 0;
          this.dataFolios.FoliosDisponibles = 0;
          this.dataFolios.FoliosAlertar = 0;
          this.dataFolios.FoliosRepetirAlerta = 0;
        } else {
          this.dataFolios.FoliosComprados = data.FoliosComprados;
          this.dataFolios.FoliosDisponibles = data.FoliosDisponibles;
          this.dataFolios.FoliosAlertar = data.FoliosAlertar;
          this.dataFolios.FoliosRepetirAlerta = data.FoliosRepetirAlerta;
        }
        this.setParamControlFolios();
      },
      deep: true,
    },
    "$store.state.folios.statusResponseFoliosFromHKA": {
      handler(data) {
        this.loadingFoliosHKA = false;
        this.$store.dispatch("folios/getFoliosData", { IdCompania: this.idCompania });
        this.successAlert = true;
        this.msgSuccessAlert = "Folios actualizados exitosamente";
        setTimeout(() => {
          this.successAlert = false;
        }, 5000);
      },
    },
    formHasErrors() {
      if (this.formHasErrors === false) {
        this.sendFoliosData();
      }
    },
    statusResponseFolios() {
      if (this.statusResponseFolios === true) {
        this.generateAlertSuccess();
        this.formHasErrors = null;
        this.$store.dispatch("folios/setStatusResponse", false);
      }
    },
    idCompania() {
      if (this.idCompania && this.isAdmin) {
        this.isSearchCompany = false;
        this.formHasErrors = null
        this.isHKA = null;
      }
    }
  },

  methods: {
    async getFoliosData() {
      await this.$store.dispatch("folios/getFoliosData", { IdCompania: this.idCompania });
    },
    async getListClients() {
      await this.$store.dispatch('folios/getListClients')
    },
    async getFoliosDataFromHKA() {
      await this.$store.dispatch('folios/getFoliosDataFromHKA', { idCompania: this.idCompania })
    },
    validateCompanyField(value) {
      if ((!value || value <= 0) && this.firstTime === false) {
        this.companySelectorError = true
        this.helperSelectorText = 'El campo no puede estar vacío.'
        return false
      } else {
        this.companySelectorError = false
        this.helperSelectorText = ''
        return true
      }
    },

    setParamControlFolios() {
      if (this.$store.state.folios.foliosData.length == 0) {
        this.paramControlFolios = false;
        return;
      }

      let dataParam = this.$store.state.folios.foliosData.parametros.map(
        (value) => (value.Nombre == "FE_CONTROL_FOLIOS" ? value : false)
      );
      if (dataParam.length > 0) {
        this.paramControlFolios = dataParam[0].Valor == "1" ? true : false;
        return;
      }

      this.paramControlFolios = false;
    },
    generateAlertSuccess() {
      this.successAlert = true;
      this.msgSuccessAlert = "Alerta para folios guardada exitosamente";
      this.$store.dispatch("folios/getFoliosData", { IdCompania: this.idCompania });
      this.clearForm();
      setTimeout(() => {
        this.successAlert = false;
      }, 3000);
    },
    validateFoliosData() {
      this.formHasErrors = null;
      Object.keys(this.foliosForm).forEach((campo) => {
        if (!this.foliosForm[campo]) {
          this.formHasErrors = true;
        }
        this.$refs[campo].validate(true);
      });
      this.formHasErrors = !this.$refs.foliosForm.validate();
    },
    closeDialogShowTokenFolios() {
      this.$refs["casoSalesforce"].reset();
      this.$refs["horasGracia"].reset();
      this.dialogShowTokenFolios = false
    },
    generateTokenFolios() {
      this.errorAlert = false;
      if (!this.$refs.foliosFormToken.validate()) {
        return false;
      }

      let horasGracia = this.dataTokenFolios.horasGracia.toString().padStart(2, '0');
      let ultimosDigitosCaso = this.dataTokenFolios.casoSalesforce.toString().slice(-2); // ultimos dos digitos del caso de salesforce

      let timestamp = Date.now();

      // Convertir timestamp a fecha/hora en UTC
      let date = new Date(timestamp);
      let year = date.getUTCFullYear();
      let month = String(date.getUTCMonth() + 1).padStart(2, '0'); // +1 porque los meses van de 0 a 11
      let day = String(date.getUTCDate()).padStart(2, '0');
      let hour = String(date.getUTCHours()).padStart(2, '0');
      let minuteStr = String(date.getUTCMinutes()).padStart(2, '0');

      let cadenaNumerica = `${year}${month}${day}${hour}${minuteStr}`;
      let digitoVerificador = this.calculateDigitChecker(cadenaNumerica);
      let minutoGeneradoToken = minuteStr;


      this.dataTokenFolios.tokenGenerado = `${minutoGeneradoToken}${ultimosDigitosCaso}${horasGracia}${digitoVerificador}`

      if (this.dataTokenFolios.tokenGenerado.length != 7) { // 7 digitos es la longitud del token
        this.errorAlert = true
        this.msgErrorAlert = "No es posible generar token";
        return false;
      }

      this.dataTokenFolios.idCompania = this.idCompania

      this.$store.dispatch("folios/insertLogTokenFolios", {
        IdCompania: this.dataTokenFolios.idCompania,
        CasoSalesforce: this.dataTokenFolios.casoSalesforce,
        HorasGracia: this.dataTokenFolios.horasGracia,
        TokenGenerado: this.dataTokenFolios.tokenGenerado,
        Usuario: this.dataTokenFolios.user,
      });

      this.dialogShowTokenFolios = true
      console.log("token generado", this.dataTokenFolios.tokenGenerado)
    },
    calculateDigitChecker(stringDate) {
      let plus = 0;
      for (let char of stringDate) {
        plus += parseInt(char, 10);
      }
      return plus % 10;
    },
    searchCompany() {
      this.firstTime = false
      let valueHKA = listTechnologyProvider.filter((element) => element.textEnvironment == "HKA")
      let datosCompania = (this.$store.state.folios.listClients.Mensaje || []).filter((compania) => compania.IdCompania === this.idCompania)
      if(datosCompania.length > 0 && valueHKA.length > 0  ){
          this.isHKA = datosCompania[0].ProveedorTecnologico == valueHKA[0].valueEnvironment
          if (this.isHKA && this.validateCompanyField(this.idCompania)) {
            this.getFoliosData();
            this.isSearchCompany = true;
          }
      }

    },
    actualizarFoliosDesdeHKA() {
      this.loadingFoliosHKA = true
      this.getFoliosDataFromHKA()
    },
    sendFoliosData() {
      this.$store.dispatch("folios/updateFoliosData", {
        ...this.dataFolios, "ValorParamControlFolios": this.paramControlFolios == true ? 1 : 0, IdCompania: this.idCompania
      });
    },
    clearForm() {
      this.$refs.foliosForm.reset();
    },
  },
};
</script>
